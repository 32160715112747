<template>
<div class="association_company">
  <div class="content">
<!--  <p>关联企业</p>-->
  <van-cell class="company-cell" title="关联企业" :value="company"  is-link size="large"  @click="toAssociation" />

  <companyInfo-card :val="companyInfo" v-if="companyInfo"></companyInfo-card>

  </div>

  <div class="btn">
    <van-button class="btn-left" color="#EA0B06" block @click="association">确认关联</van-button>
  </div>


</div>
</template>

<script>
import { getAddCompany, getCompanyInfo } from '@/api/res-company'
import companyInfo_card from '@/page/verified/components/companyInfo_card'
import { Dialog, Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  name: 'association_company',
  data () {
    return {
      company:'请选择',
      companyInfo:'',
    }
  },
  components:{
    'companyInfo-card':companyInfo_card
  },
  computed:{
    id: function () {
      return this.$route.query.id
    },
    ...mapGetters(["userInfo"]),
  },
  mounted () {
    document.title = '关联企业'
    if(this.id) this.loadCompanyInfo()
  },
  methods:{
    loadCompanyInfo(){
      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });
      getCompanyInfo(this.id).then(res=>{
        console.log(res,'res======')
        this.company=res.companyName
        this.companyInfo=res
        Toast.clear()
      })
    },


    toAssociation(){
        this.$router.push('/verified/company_list')
    },

    association(){

      if(this.id){

        if(this.userInfo.verifyStatus==='3'){
          Toast.loading({
            message: '提交中',
            forbidClick: true,
          });
          getAddCompany(this.id).then(()=>{
            Toast.clear()
            this.$router.push('/verified')
            Toast("提交成功");
          })
        }else {
          Dialog.confirm({
            message: '请先完成实名认证',
            confirmButtonText:'去实名',
          })
            .then(() => {
              this.$router.push('/verified_personal')
            })

        }

      }

    },
  },

}
</script>

<style scoped lang="scss">
.association_company{
  width: 100vw;
  min-height: calc(100vh - 56px);
  background: #F6F6F6;
  .content{
    width: 100vw;
    padding: 16px;
    >p{
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 16px;
    }
    .companyInfo-card{
      margin-bottom: 100px;
    }
    >.company-cell{
      display: flex;
      justify-content: flex-start;
      &::v-deep{
        .van-cell__title,.van-cell__value{
          font-size: 16px;
          //font-weight: 500;
          color: #333333;
          flex: 0 1 auto;
          width: 192px;
        }
        .van-cell__title{
          width: 121px;
        }
      }
    }

  }
}


.btn{
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 0px;
}

</style>
